<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('hub.resources.overview')">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="5" xl="5" class="pt-0 pb-0 text-left">
              {{$t('resources.Resources')}}
            </CCol>
            <CCol cols="7" xl="7" class="pt-0 pb-0 text-xl-right">
              <div v-if="checkPermission('hub.resources.add')" class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-2 link" color="primary" @click="openSidebarRight('resource_details', { resource_id_external: null })">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('resources.Add_resource') }}</span>
                </CButton>
              </div> 
            </CCol> 
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <b-table ref="resourcesTable"
                       class="data_table"
                       :data="resources"
                       :striped="true"
                       :hoverable="true"
                       :mobile-cards="true"
                       :paginated="isPaginated"
                       :per-page="perPage"
                       :current-page.sync="currentPage"
                       :pagination-simple="isPaginationSimple"
                       :pagination-position="paginationPosition"
                       :total="totalItems"
                       backend-pagination
                       @page-change="onPageChange">                       
                
                <template slot-scope="props">
                  <b-table-column field="icon" :label="$t('resources.Icon')" width="5%">
                    <span v-if="props.row.icon_class !== null"><i :class="'fas fa-' + props.row.icon_class"></i></span>
                    <span v-else>-</span>
                  </b-table-column>
                  <b-table-column field="resource_name" :label="$t('resources.Name_resource')" :searchable="searchEnabled" width="20%">
                    <span>{{props.row.resource_name}}</span>
                  </b-table-column>
                  <b-table-column field="description" :label="$t('common.Description')" :searchable="searchEnabled">
                    <span>{{props.row.description ? props.row.description : '-'}}</span>
                  </b-table-column>
                  <b-table-column field="headcount.headcount_total" label="" width="10%">
                    <span class="d-flex pointer" @click="openSidebarRight('headcount_details', { headcount_type: 'resource', headcount_id_external: props.row.resource_id_external });">
                      <i class="icon top far fa-user mr-1"/>{{props.row.headcount.headcount_total}}
                    </span>
                  </b-table-column>                                   
                  <b-table-column field="actions" label="" width="10%" :visible="checkPermission('hub.resources.edit')">
                    <div class="d-flex justify-content-lg-end">
                      <b-switch size="is-small" 
                                class="mb-0 mr-2" 
                                v-model="props.row.is_active"
                                :disabled="props.row.from_marketplace === 'Y'"
                                v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('hub.Resource_managed_by_owner')} : ''"
                                @input.stop="props.row.from_marketplace === 'N' ? updateResource(props.row.resource_id_external, props.row) : null;">
                        {{$t('is_active')}}
                      </b-switch>
                      <CButton color="primary"
                               class="m-0"
                               v-bind:class="{'disabled' : props.row.from_marketplace === 'Y'}"
                               v-c-tooltip="props.row.from_marketplace === 'Y' ? {placement: 'left', content: $t('hub.Resource_managed_by_owner')} : ''"
                               @click.stop="props.row.from_marketplace === 'N' ? openSidebarRight('resource_details', { resource_id_external: props.row.resource_id_external }) : null">
                        <i class="fas fa-pen"/>
                      </CButton>
                    </div>
                  </b-table-column>                                      
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('resources.Loading_resources')"/>
                    <getStarted v-else ref="getStarted" :content="$t('start.resources')" :showVideo="false" :youtubeId="'IKqV7DB8Iwg'"/>
                  </div>                
                </template>                
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import { fontAwesomePicker } from 'font-awesome-picker';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import getStarted from '@/components/common/getStarted.vue';

export default {
  name: 'Resources',
  components: {
    QuickEdit,
    Multiselect,
    fontAwesomePicker,
    loadingSpinner,
    noPermission,
    getStarted
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      resources: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: true,
      totalItems: 0,
      resourcesStartIndex: 0      
    }
  },
  methods: {
    getResourceDetails(resourceIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/resource/' + resourceIdExternal)
      .then(res => {
        let updatedResource = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.resourcesTable !== undefined ) {
          // Get the resource index
          let resourceIndex = this.$refs.resourcesTable.data.findIndex(x => x.resource_id_external == updatedResource.resource_id_external);
          // Update the is active value for the row
          this.$refs.resourcesTable.data[resourceIndex].resource_name = updatedResource.resource_name;
          this.$refs.resourcesTable.data[resourceIndex].description = updatedResource.description;
          this.$refs.resourcesTable.data[resourceIndex].icon_class = updatedResource.icon_class;
          this.$refs.resourcesTable.data[resourceIndex].is_active = updatedResource.is_active;
          this.$refs.resourcesTable.data[resourceIndex].headcount = updatedResource.headcount;
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    updateResource(resourceIdExternal, data) {
      let params = {}
      params.resourceData = data;
      params.resourceData.language_id = 2;
      if(params.resourceData.description === '') params.resourceData.description = null;
      (params.resourceData.is_active == true) ? params.resourceData.active = 'Y' : params.resourceData.active = 'N';

      let resource_name = params.resourceData.resource_name;
      let content = params.resourceData.content;

      if(resource_name && content) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/hub/resource/' + resourceIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('resources.Resource_updated'), type: 'is-success', duration: 2000 });          
          this.getResourceDetails(resourceIdExternal);
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }            
    },
    getResources() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/resources/' + this.resourcesStartIndex)
      .then(res => {
        // Clear the resources array
        this.resources = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_resources;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the resources to the resources array
        this.resources = res.data.data.resources;
        // Enable the pagination if necessary
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.overviewDataLoaded = true;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    pauseGetStarted() {
      if(this.resources.length === 0) {
        setTimeout(function(){
          this.$refs.getStarted.pauseVideo();
        }.bind(this), 100);
      } 
    },
    onPageChange(page) {
      // Set the resourcesStartIndex value
      (page > 1) ? this.resourcesStartIndex = (page - 1)*10 : this.resourcesStartIndex = 0;
      // Get the rewards
      this.getResources();
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }          
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.getResources();

    this.$bus.$on('update_resources', () => {
      this.getResources();
    }); 

    this.$bus.$on('update_resource_details', (resourceIdExternal) => {
      this.getResourceDetails(resourceIdExternal);
    });
  },
  beforeDestroy() {
    this.$bus.$off('update_resources');
    this.$bus.$off('update_resource_details');
  }  
}
</script>